export default {
  ADD: '/api/report/add',
  EDIT: '/api/report/edit',
  ITEM: '/api/report/item',
  LIST: '/api/report/list',
  DELETE: '/api/report/delete',
  STATUS_LIST: '/api/report/status/index',
  TYPE_ADD: '/api/report/type/add',
  TYPE_EDIT: '/api/report/type/edit',
  TYPE_DELETE: '/api/report/type/delete',
  TYPE_ITEM: '/api/report/type/item',
  TYPE_LIST: '/api/report/type/list'
}
