export default [
  {
    path: 'objectList',
    name: 'ObjectList',
    component: () => import('@/components/objects/ObjectList.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: ':id',
        name: 'ObjectItem',
        component: () => import('@/components/objects/ObjectItem.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
]