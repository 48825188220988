const tokens = {
  '#': { pattern: /\d/ },
  X: { pattern: /[0-9a-zA-Z]/ },
  S: { pattern: /[a-zA-Z]/ },
  A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
  a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
  '!': { escape: true }
}
// Facade to maskit/dynamicMask when mask is String or Array
function masker (value, mask, masked = true, tokens) {
  return Array.isArray(mask) ? dynamicMask(maskit, mask, tokens)(value, mask, masked, tokens) : maskit(value, mask, masked, tokens)
}

function dynamicMask (maskit, masks, tokens) {
  masks = masks.sort((a, b) => a.length - b.length)
  return function (value, mask, masked = true) {
    var i = 0
    while (i < masks.length) {
      var currentMask = masks[i]
      i++
      var nextMask = masks[i]
      if (!(nextMask && maskit(value, nextMask, true, tokens).length > currentMask.length)) {
        return maskit(value, currentMask, masked, tokens)
      }
    }
    return '' // empty masks
  }
}

function maskit (value, mask, masked = true, tokens) {
  value = value || ''
  mask = mask || ''
  var iMask = 0
  var iValue = 0
  var output = ''
  var cMask = ''
  while (iMask < mask.length && iValue < value.length) {
    cMask = mask[iMask]
    var masker = tokens[cMask]
    var cValue = value[iValue]
    if (masker && !masker.escape) {
      if (masker.pattern.test(cValue)) {
        output += masker.transform ? masker.transform(cValue) : cValue
        iMask++
      }
      iValue++
    } else {
      if (masker && masker.escape) {
        iMask++ // take the next mask char and treat it as char
        cMask = mask[iMask]
      }
      if (masked) output += cMask
      if (cValue === cMask) iValue++ // user typed the same char
      iMask++
    }
  }

  // fix mask that ends with a char: (#)
  var restOutput = ''
  if (masked) {
    while (iMask < mask.length) {
      cMask = mask[iMask]
      if (tokens[cMask]) {
        restOutput = ''
        break
      }
      restOutput += cMask
      iMask++
    }
  }

  return output + restOutput
}

function event (name) {
  var evt = document.createEvent('Event')
  evt.initEvent(name, true, true)
  return evt
}

function onInputChange (elm, config) {
  elm.oninput = function (evt) {
    if (!evt.isTrusted) return
    var position = elm.selectionEnd
    var digit = elm.value[position - 1]
    elm.value = masker(elm.value, config.mask, true, config.tokens)
    while (position < elm.value.length && elm.value.charAt(position - 1) !== digit) {
      position++
    }
    if (elm === document.activeElement) {
      elm.setSelectionRange(position, position)
      setTimeout(function () {
        elm.setSelectionRange(position, position)
      }, 0)
    }
    elm.dispatchEvent(event('input'))
  }

  var newDisplay = masker(elm.value, config.mask, true, config.tokens)
  if (newDisplay !== elm.value) {
    elm.value = newDisplay
    elm.dispatchEvent(event('input'))
  }
}

export default function (el, binding) {
  var config = binding.value
  if (Array.isArray(config) || typeof config === 'string') {
    config = {
      mask: config,
      tokens: tokens
    }
  }

  if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
    var els = el.getElementsByTagName('input')

    for (let i = 0; i < els.length; i++) {
      onInputChange(els[i], config)
    }
  } else {
    onInputChange(el, config)
  }
}
