import App from './components/App.vue'
import Http from '@/api/http'
import store from '@/store'
import VueAxios from 'vue-axios'
import { createApp } from 'vue'
import { convertDate, convertPrice } from '@/helps'
import router from './router'
import inputMask from '@/utils/mask'
import ElementPlus from 'element-plus'
import elementLangRu from 'element-plus/lib/locale/lang/ru'
import mitt from 'mitt'
import 'element-plus/dist/index.css'
import '@/styles/theme/dark.scss'
import '@/styles/base.scss'
import '@/styles/helpers.scss'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import Lang from './lang'
import clickOutside from '@/directives/clickOutside'

const app = createApp(App)
const eventBus = mitt()

dayjs.locale('ru')

app.config.globalProperties.$filters = { convertDate, convertPrice }
app.config.globalProperties.$consts = {
  maxWordLimitTextarea: 2000
}

app.directive('mask', inputMask)
app.directive('click-outside', clickOutside)

app.use(store)
app.use(router)
app.use(VueAxios, Http)
app.use(ElementPlus, { locale: elementLangRu })
app.use(Lang)

app.config.globalProperties.eventBus = eventBus

app.provide('axios', app.config.globalProperties.$http)
app.provide('eventBus', app.config.globalProperties.eventBus)

app.mount('#app')
