<template>
  <div
    class="app-crm"
    :class="{ 'dark': getTheme } ">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    ...mapGetters('oauth', ['getTheme'])
  }
}
</script>

<style lang="scss" scoped>
  .app-crm {
    overflow: hidden;
  }
</style>
