import Store from '@/store'

export default [
  {
    path: 'report',
    name: 'Report',
    component: () => import('@/components/AppPage.vue'),
    redirect: { name: 'ReportList' },
    meta: {
      title: 'Отчеты',
      requiresAuth: true,
      breadcrumb: {
        dynamic: false
      }
    },
    children: [
      {
        path: 'list',
        name: 'ReportList',
        component: () => import('@/components/report/ReportList.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ':id',
        name: 'ReportItem',
        component: () => import('@/components/report/ReportItem.vue'),
        redirect: { name: 'ReportJournal' },
        meta: {
          title: 'Отчет',
          requiresAuth: true,
          breadcrumb: {
            dynamic: true,
            getBreadcrumbTitle: () => {
              return Store.getters['report/report']?.report_number
            }
          }
        },
        beforeEnter: async (to, from, next) => {
          await Store.dispatch('report/doReportGet', { params: { id: to.params.id } })
          // TODO: убирать отчет из памяти на выходе
          next()
        },
        children: [
          {
            name: 'ReportJournal',
            path: 'journal',
            component: () => import('@/components/report/ReportJournal.vue'),
            meta: {
              title: 'Журнал',
              requiresAuth: true,
              breadcrumb: {
                dynamic: false
              }
            }
          }
        ]
      }
    ]
  }
]