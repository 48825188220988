import { createRouter, createWebHistory } from 'vue-router'
import qs from 'qs'
import { routes } from './routes'
import { prepareWithRemovesEmptyObj } from '@/helps'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  parseQuery (query) {
    return qs.parse(query, { arrayFormat: 'brackets' })
  },
  stringifyQuery (query) {
    return qs.stringify(prepareWithRemovesEmptyObj(query), { arrayFormat: 'brackets', encode: false, skipNulls: true })
  }
})

router.beforeEach((to, from, next) => {
  const { title = '' } = to.meta
  // TODO: наименование(ACRM) брать из конфига
  if (title) document.title = `ACRM - ${title}`
  next()
})

export default router
