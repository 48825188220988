import Http from '@/api/http'

export default {
  namespaced: true,
  state: {
    authorized: false,
    data: {},
    profile: {},
    theme: false
  },
  getters: {
    getAuthorized: state => state.authorized,
    getUserData: state => state.data,
    getTheme: state => state.theme,
    isUserAdmin: state => state.data.systype_id === 1 || false // admin group
  },
  mutations: {
    SET_AUTORIZED (state, response) {
      state.authorized = true
      state.data = response.item
    },
    SET_ANAUTORIZED (state) {
      state.authorized = false
      state.data = {}
      localStorage.removeItem('auth')
    },
    SET_THEME (state) {
      state.theme = !state.theme
    }
  },
  actions: {
    userLogin ({ commit }, data = {}) {
      return Http.post('/api/oauth/auth_password', data).then(response => {
        const { token_type, access_token } = response.data.item
        Http.defaults.headers.Authorization = token_type + ' ' + access_token
        localStorage.setItem('auth', JSON.stringify(response.data.item))
        commit('SET_AUTORIZED', {})
      })
    },
    userLogout ({ commit }) {
      return Http.get('/api/oauth/logout').then(() => {
        commit('SET_ANAUTORIZED')
      })
    },
    userInfo ({ commit }) {
      return Http.get('/api/state/user_info').then(response => {
        commit('SET_AUTORIZED', response.data)
      })
    },
    changeTheme ({ commit }) {
      commit('SET_THEME')
    }
  }
}
