import Axios from 'axios'
import router from '@/router'
import Store from '@/store'
import { ElMessage } from 'element-plus'
import qs from 'qs'
import { prepareWithRemovesEmptyObj } from '@/helps'

const token = JSON.parse(localStorage.getItem('auth')) || {}
const headers = {
  'X-Project-Name': 'acrm 2.0',
  'x-sysowner-code': 'oks',
  'Accept-Language': 'ru',
  Authorization: token ? token.token_type + ' ' + token.access_token : ''
}
const Http = Axios.create({
  headers,
  paramsSerializer (params) {
    return qs.stringify(prepareWithRemovesEmptyObj(params), { arrayFormat: 'brackets', encode: false, skipNulls: true })
  }
})

Http.interceptors.response.use(response => {
  return Promise.resolve(response.data)
}, error => {
  const { response = {} } = error
  if (response.status === 401) {
    if (!Store.getters['oauth/getAuthorized']) router.push({ name: 'Auth' })
  }

  let message = ''
  if (response?.data?.message) {
    message = response.data.message
    ElMessage({
      showClose: true,
      message: message,
      type: 'error',
      duration: 2000,
      dangerouslyUseHTMLString: true
    })
  }
  return Promise.reject(error)
})

export default Http
