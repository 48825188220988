import Store from '@/store'

import report from '@/router/modules/report'
import thesaurus from '@/router/modules/thesaurus'
import object from '@/router/modules/object'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/components/AppIndex.vue'),
    meta: {
      requiresAuth: true
    },
    redirect: () => {
      if (!Store.getters['oauth/getAuthorized']) {
        return { name: 'Auth' }
      } else {
        return { name: 'ReportList' }
      }
    },
    beforeEnter: async (to, from, next) => {
      const requests = [
        Store.dispatch('report/doStatusList')
      ]
      await Store.dispatch('oauth/userInfo')
      await Promise.all(requests)
      next()
    },
    children: [
      ...report,
      ...thesaurus,
      ...object
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/components/Auth.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

export { routes }