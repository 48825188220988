import { createStore } from 'vuex'
import oauth from './modules/oauth'
import state from './modules/state'
import report from './modules/report'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    state,
    oauth,
    report
  }
})
