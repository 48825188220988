export default [
  {
    path: 'thesaurus-report-type',
    name: 'ThesaurusReportTypes',
    component: () => import('@/components/thesaurus/ReportType.vue'),
    meta: {
      title: 'Типы отчетов',
      breadcrumb: {
        dynamic: false
      }
    }
  }
]