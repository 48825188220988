import { serialize } from 'object-to-formdata'
import { isObject, isArray, isNumber, isString, isUndefined, cloneDeep } from 'lodash'

// Важно, если LANG будет динамическим, то изменить тесты функций, где используется значение LANG
const LANG = 'RU-ru'

/**
 * Получение из строки даты
 * @param dateString {String}
 * @returns {string}
 */

export function convertDate (dateString) {
  if (_isDateConvertable(dateString)) {
    return new Date(dateString).toLocaleDateString(LANG)
  } else {
    return '-'
  }
}

/**
 * Можно ли строку преобразовать при помощи Date()
 * @param dateString {string}
 * @returns {boolean}
 */

function _isDateConvertable(dateString) {
  if (!isString(dateString)) return false
  const createdDate = new Date(dateString)
  return (!isNaN(createdDate) && createdDate.toString() !== 'Invalid Date')
}

/**
 * Получение из строки даты и времени
 * @param dateString {String}
 * @returns {string}
 */

export function convertDateAndTime (dateString) {
  if (_isDateConvertable(dateString)) {
    return new Date(dateString).toLocaleString(LANG, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  } else {
    return '-'
  }
}

/**
 * Получение из числа цены
 * @param number {Integer | String}
 * @param lang
 * @returns {string}
 */

export function convertPrice (number) {
  if ((isString(number) || isNumber(number)) & !isNaN(+new Number(number))) {
    const convertedPrice = new Intl.NumberFormat(LANG, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number)
    return convertedPrice
  } else {
    return '-'
  }
}

/**
 * Конвертация данных в формат FormData
 * @param data {object}
 */

export function convertToFormData (data) {
  const formData = serialize(data)
  return formData
}

/**
 * Извлечение в целевой объект значений из объекта с данными, если совпадают ключи
 * @param target
 * @param source
 * replaceObjectValues(target = {a: ''}, source = {a: 22, b: 33}) => target = {a: 22}
 */
export function replaceObjectValues (target = {}, source = {}) {
  if (isObject(target) && isObject(source)) {
    Object.keys(target).forEach(key => {
      if (Object.hasOwnProperty.call(source, key)) {
        target[key] = source[key]
      }
    })
  }
}

export function buildQueryObject ({ prop, order } = {}) {
  if (!prop) return {}
  return {
    s: {
      [prop]: order.replace(/(desc|asc)ending/, '$1')
    },
    p: {
      page: 1
    }
  }
}

/**
 * Сброс обьекта
 * @param object
 */
export function resetObject (object, excludeKeysArr = []) {
  Object.keys(object).forEach((value, key, objRef) => {
    const isKeyExclude = excludeKeysArr.indexOf(key) !== -1
    if (isKeyExclude) return
    let result
    if (isObject(value) && !isArray(value)) {
      resetObject(objRef[key])
    } else {
      if (isArray(value)) {
        result = []
      }
      objRef[key] = result
    }
  })
}

/**
 * Клонирование объекта с рекурсивным удалением пустых свойств и объектов с пустыми полями
 * удаляет поля с [], undefined, ''
 * @param object
 * @return {Object}
 * {f: {
        subject_id: {
          eq: 77
        },
        status_id: {
          in: []
        }
      }
    } => {f: {subject_id: {eq: 77}}}
 */
export function prepareWithRemovesEmptyObj (object) {
  if (!isObject(object)) return
  object = cloneDeep(object)
  clearEmptiesProps(object)
  return object
}

export function clearEmptiesProps (o) {
  Object.entries(o).forEach(([key, val]) => {
    if (isArray(val)) {
      if (!val.length) {
        delete o[key]
      } else {
        val.forEach(obj => clearEmptiesProps(obj))
      }
    } else if (!isObject(val)) {
      if (val === '' || isUndefined(val)) delete o[key]
      return
    }
    clearEmptiesProps(val)
    if (!Object.keys(val).length) delete o[key]
  })
}

