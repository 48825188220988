import Http from '@/api/http'
import { REPORT } from '@/api'

export default {
  namespaced: true,
  state: {
    report: null,
    reportStatusList: []
  },
  getters: {
    report: state => state.report,
    reportCalculations: state => state.report.calculations || [],
    reportStatusList: state => state.reportStatusList || []
  },
  mutations: {
    SET_REPORT (state, report) {
      state.report = report
    },
    SET_STATUS_LIST (state, statusList) {
      state.reportStatusList = statusList
    }
  },
  actions: {
    doReportGet ({ commit }, data = {}) {
      return Http.get(REPORT.ITEM, data).then(r => {
        commit('SET_REPORT', r.data.item)
      })
    },

    doStatusList ({ commit }) {
      return Http.get(REPORT.STATUS_LIST).then(r => {
        commit('SET_STATUS_LIST', r.data.list)
      }).catch(() => {})
    }
  }
}
